// Common
import { Modal } from "bootstrap";

// Common
import { default as init } from "./common/base";

// Helpers
import { Component } from "./helpers/component";

// Components
import { Slider } from "../../components/slider/slider";
import { Share } from "../../components/share/share";
import { Menu } from "../../components/menu/menu";

//import { Header } from "../../components/header/header";
import Swiper from "swiper";

// Registering components
//Component.register("Header", Header);
Component.register("Slider", Slider);
Component.register("Share", Share);
Component.register("Menu", Menu);

init();

document.addEventListener('DOMContentLoaded', () => {

  $(window).scroll(function(){
    const currentScroll = $(window).scrollTop();

    if ( !$("body").hasClass('zaux-menu-active') ){
      if (currentScroll > 0) {
        $(".c-header__logo--full").fadeOut(300);
        $(".c-header__logo--full").addClass("scroll-down");
      }
      else {
        $(".c-header__logo--full").fadeIn(300);
        $(".c-header__logo--full").removeClass("scroll-down");
      }
    }
  if (currentScroll > 24 ) {
    if ( $(".c-breadcrumbs").hasClass("c-breadcrumbs--zi") ){
      $(".c-breadcrumbs").removeClass("c-breadcrumbs--zi");
    }
  }
  else {
    if ( !$(".c-breadcrumbs").hasClass("c-breadcrumbs--zi") ){
      $(".c-breadcrumbs").addClass("c-breadcrumbs--zi");
    }
  }
  });

  $(`.c-menu__nav .c-menu__nav--item a`).click(function(){
    // event.preventDefault();
    if (!$(this).parents('.c-menu__nav--item').hasClass('open')){
      $(`.c-menu__nav--item`).removeClass('open');
      $(this).parents('.c-menu__nav--item').addClass('open');
    }
    else {
      $(this).parents('.c-menu__nav--item').removeClass('open');
    }
  });

  $(`.c-footer__nav .c-footer__nav--item--click`).click(function(){
    event.preventDefault();
    let footerTrigger = $(this).parents(".c-footer__nav--item");
    if (!footerTrigger.hasClass('open')){
      $(`.c-footer__nav--item`).removeClass('open');
      footerTrigger.addClass('open');
    }
    else {
      footerTrigger.removeClass('open');
    }
  });

  $(`.c-displayblock2__listing--item`).click(function(){
    event.preventDefault();
    if (!$(this).hasClass('open')){
      $(`.c-displayblock2__listing--item`).removeClass('open');
      $(this).addClass('open');
    }
    else {
      $(this).removeClass('open');
    }
  });

  var viewportWidth = $(window).width();
  if ( viewportWidth < 992 ) {
    $('.c-card2__content--text p').each(function(){
      var words = $(this).text().split(" ");
      var maxWords = 14;
      var shortWords = Words(words.slice(0,maxWords));
      var longWords = Words(words.slice(maxWords, words.length));
        if(words.length > maxWords){

          var html = shortWords +'<span class="more_text" style="display:none;"> '+ longWords +'</span>' + '<a href="#" class="read_more">... <span>Altro</span></a>'

            $(this).html(html)

          $(this).find('a.read_more').click(function(event){
                $(this).toggleClass("less");
                event.preventDefault();
                if($(this).hasClass("less")){
                  $(this).html("<br/> <span>Riduci</span>")
                    $(this).parent().find(".more_text").show();
                }else{
                  $(this).html("... <span>Altro</span>")
                    $(this).parent().find(".more_text").hide();
                }
            })
        }
    })
  }

  function Words(w) {
    var text ="";
    for (let i = 0; i < w.length; i++) {
      text += w[i] + " ";
    }
    return text
  }

});
