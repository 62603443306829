import { Dom } from "../../assets/js/helpers/dom";
import { Utils } from "../../assets/js/helpers/utils";

export class Menu {
  static cssClass = "c-menu";

  constructor(el = null) {
    this.$el = $(el);
    this.setupEvents();
  }

  setupEvents() {

    var viewportWidth = $(window).width();
    const $navItem = $(`.${Menu.cssClass}__nav--item`)
    if (viewportWidth < 992) {
      $navItem.addClass('mobile-menu');
    }
    else {
      if ( $navItem.hasClass('mobile-menu') ) {
        $navItem.removeClass('mobile-menu');
      }
    }

    $(document).on('click', `.${Menu.cssClass}__btn-toggle`, (e) => {
      e.preventDefault();
      const _this = $(`.${Menu.cssClass}`)[0].instance;
      _this.toggleMenu();
    });
    // $(document).on('click', `.${Menu.cssClass}__nav--item.item-has-child.mobile-menu`, (e) => {
    //   e.preventDefault();
    //   const _this = $(`.${Menu.cssClass}`)[0].instance;
    //   _this.toggleMenu();
    // });

  }

  toggleMenu(doOpen) {
    const $btns = $(`.${Menu.cssClass}__btn-toggle`);
    const $menu = $(`.${Menu.cssClass}`);
    const cssClassActive = `${Utils.getVarNS()}-menu-active`;
    const cssMenuActive = `open`;
    const bodyClassList = document.body.classList;
    const $logo = $(`.c-header__logo--full`);

    if (doOpen === undefined) {
      doOpen = !bodyClassList.contains(cssClassActive);
    }

    if (doOpen) {
      $btns.addClass('is-active');
      bodyClassList.add(cssClassActive);
      $menu.addClass(cssMenuActive);
      $logo.fadeIn(300);

    } else if (!doOpen) {
      $btns.removeClass('is-active');
      bodyClassList.remove(cssClassActive);
      $menu.removeClass(cssMenuActive);
      if ( $logo.hasClass("scroll-down") ){
      $logo.fadeOut(300);
      }
    }
  }


}
